import React from 'react'
import { Button } from 'semantic-ui-react'
import { Header } from './components/Header'
import { getSoundNotificationsState } from './core/local-storage.service'
import { useReduxState } from './reducers/root-reducer'
import { isSoundSelected, setSelectedSound, Sounds } from './sounds'

export function HomePage() {
  const user = useReduxState(s => s.user)
  return (
    <div>
      <Header />
      <div className="flex items-center justify-center" style={{ height: '70vh' }}>
        <img src="/Logo_APEE.jpg" alt="APEE" className="w-px-300" />
      </div>

      {user && user.loggedIn && (
        <div className="fixed bottom-0 left-0 w-full">
          <div className="gap-px-4 pb-px-10 m-auto flex max-w-2xl flex-col items-center justify-center">
            <div>
              <span>Notificações Sonoras: </span>
              <span>
                {getSoundNotificationsState().active ? <span className="text-green-500">ACTIVO</span> : <span className="text-gray-500">INACTIVO</span>}
              </span>
            </div>
            <Button.Group secondary inverted size="mini">
              {Sounds.map(sound => (
                <Button
                  key={sound.name}
                  active={isSoundSelected(sound)}
                  onClick={() => setSelectedSound(sound).then(() => setTimeout(() => window.location.reload(), 3000))}
                  content={sound.name}
                />
              ))}
            </Button.Group>
          </div>
        </div>
      )}
    </div>
  )
}
