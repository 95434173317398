import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: LoggedInUser = {
  loggedIn: false,
  name: '',
  email: '',
  role: 'guest'
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => ({ ...state, loggedIn: action.payload }),
    setUser: (_, action: PayloadAction<LoggedInUser>) => action.payload,
    clearUser: () => initialState
  }
})

type LoggedInUser = {
  loggedIn: boolean
  name: string
  email: string
  role: 'guest' | 'admin' | 'employee'
}
