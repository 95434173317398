import React from 'react'
import { useDispatch } from 'react-redux'
import { API } from '../../api'
import { userSlice } from '../../reducers/user-reducer'
import { logoutFirebase } from '../students-grid/firebase.api'

export function LogoutPage() {
  const dispatch = useDispatch()

  API.logout()
    .then(logoutFirebase)
    .then(() => {
      dispatch(userSlice.actions.clearUser())
      window.location.href = '/'
    })

  return <div>...</div>
}
