import React from 'react'
import { useReduxState } from '../reducers/root-reducer'

export function LoadingIndicator() {
  const loadingActivity = useReduxState(s => s.activity.loading)

  if (!loadingActivity) return null

  return (
    <div className="py-px-30 fixed top-1/4 left-0 z-50 flex w-full items-center justify-center bg-lime-50 font-extrabold text-black">
      <span>
        <strong>LOADING...</strong>
      </span>
    </div>
  )
}
