import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Unsubscribe } from 'firebase/database'
import { orderBy } from 'lodash'
import { Dispatch } from 'react'
import { onActivity, readAllActivity } from '../pages/students-grid/firebase.api'
import { FirebaseActivityItem } from '../pages/students-grid/firebase.types'

const initialState: ActivityState = {
  loading: false,
  activityItems: []
}

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    startLoading: state => ({ ...state, loading: true }),
    stopLoading: state => ({ ...state, loading: false }),
    setActivityItems: (state, action: PayloadAction<FirebaseActivityItem[]>) => ({
      ...state,
      activityItems: action.payload.reverse()
    }),
    pushActivityItem: (state, action: PayloadAction<FirebaseActivityItem>) => ({
      ...state,
      activityItems: [action.payload, ...state.activityItems, action.payload]
    })
  }
})

function sortActivity(items: FirebaseActivityItem[]) {
  return orderBy(items, a => a.when, 'desc')
}

let unsubscribe: Unsubscribe

export async function startActivityListener(dispatch: Dispatch<any>) {
  dispatch(activitySlice.actions.startLoading())
  const [items, lastKey] = await readAllActivity()
  // console.log('ALL KEYS', lastKey)
  dispatch(activitySlice.actions.setActivityItems(items))
  dispatch(activitySlice.actions.stopLoading())

  unsubscribe = onActivity(lastKey, activityItem => {
    dispatch(activitySlice.actions.pushActivityItem(activityItem))
  })
}

export async function stopActivityListener() {
  if (!unsubscribe) return
  unsubscribe()
}

type ActivityState = {
  loading: boolean
  activityItems: FirebaseActivityItem[]
}
