import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetLightClassResult } from '../../api'
import { HeaderTitle } from '../../components/HeaderTitle'
import { cx } from '../../core/cx'
import { PageContext } from '../../core/PageContext'

export default function StudentsGridHeader({
  pageContext,
  toggle = false,
  classes,
  ids
}: {
  pageContext: PageContext
  toggle?: boolean
  classes: GetLightClassResult[]
  ids?: string[]
}) {
  const [search, setSearch] = useSearchParams()

  function selectAllClasses() {
    setSearch({ ...search, sala: classes.map(c => c.id) })
  }

  return (
    <div>
      <HeaderTitle content={pageContext} />

      <div className="m-[4px] flex flex-row flex-wrap items-center justify-center gap-[2px] text-center">
        {classes.map(c => (
          <GateClassItem key={c.id} toggle={toggle} classDetails={c} selected={ids ? ids.includes(c.id) : false} />
        ))}
      </div>
      {(!ids || ids.length === 0) && (
        <p>
          Seleccione uma turma para continuar ou seleccione{' '}
          <span onClick={selectAllClasses} className="cursor-pointer underline">
            todas
          </span>
          .
        </p>
      )}
    </div>
  )
}
function GateClassItem({ toggle = false, classDetails, selected = false }: { toggle?: boolean; classDetails: GetLightClassResult; selected?: boolean }) {
  const [search, setSearch] = useSearchParams()
  const salas = search.getAll('sala')
  const totalClasses = salas.length
  const canAddClasses = true // totalClasses < 4

  function handleClick() {
    if (!toggle) {
      return setSearch({ ...search, sala: [classDetails.id] })
    }

    if (!selected && !canAddClasses) {
      return
    }

    const newSala = selected //
      ? salas.filter(s => s !== classDetails.id)
      : [...salas, classDetails.id]

    setSearch({ ...search, sala: newSala })
  }

  return (
    <span
      onClick={handleClick}
      className={cx('border-1 min-w-[60px] flex-1 justify-around border-gray-200 p-[2px] ', {
        'cursor-pointer': selected || canAddClasses,
        'bg-slate-50 text-slate-500 hover:bg-slate-50': !selected && !canAddClasses,
        'bg-slate-50 hover:bg-gray-200': !salas.includes(classDetails.id),
        'bg-gray-300': selected
      })}
    >
      {classDetails.shortName} {canAddClasses}
    </span>
  )
}
