import { combineReducers } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { activitySlice } from './activity-reducer'
import { onlineSlice } from './online-reducer'
import { userSlice } from './user-reducer'

export const rootReducer = combineReducers({
  user: userSlice.reducer,
  online: onlineSlice.reducer,
  activity: activitySlice.reducer
  // students: studentsSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function useReduxState<T>(fn: (state: RootState) => T) {
  return useSelector(fn) as T
}
