import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { API } from '../../api'
import { Header } from '../../components/Header'
import { HeaderTitle } from '../../components/HeaderTitle'
import { StudentLabel } from './StudentLabel'

export function ActivityPage() {
  const [loading, setLoading] = useState(false)
  const [activity, setActivity] = useState([] as any[])

  useEffect(() => {
    setLoading(true)
    API.getActivityLog()
      .then(setActivity)
      .then(() => setLoading(false))
  }, [])

  return (
    <div>
      <Header />
      <HeaderTitle content="Actividade recente" loading={loading} />
      <div>
        <Table
          size="small"
          className="!text-px-10"
          unstackable
          compact
          singleLine
          tableData={activity}
          headerRow={['Dia', 'Hora', 'Aluno', 'Turma', 'Estado', 'Funcionário']}
          renderBodyRow={entry => (
            <tr key={entry._id}>
              <td>{moment(entry.when).format('D MMM')}</td>
              <td>{moment(entry.when).format('LT')}</td>
              <td>{entry.studentName}</td>
              <td>{entry.className}</td>
              <td>
                <StudentLabel status={entry.status} />
              </td>
              <td>{entry.employeeName}</td>
            </tr>
          )}
        />
      </div>
    </div>
  )
}
