import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPersonChalkboard, faPersonCircleMinus, faPersonWalkingDashedLineArrowRight, faPersonWalkingLuggage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { chain, sortBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Checkbox, Message, Popup } from 'semantic-ui-react'
import { GetClassResult, Student, StudentStatus } from '../../api'
import { Header } from '../../components/Header'
import { StudentComment } from '../../components/StudentComment'
import { cx } from '../../core/cx'
import {
  getHiddenStatusFromStorage,
  getSoundNotificationsState,
  patchSoundNotificationsState,
  saveHiddenStatusToStorage
} from '../../core/local-storage.service'
import { PageContext } from '../../core/PageContext'
import { statusToName } from '../../core/statusToName'
import useInterval from '../../hooks/useInterval'
import { useReduxState } from '../../reducers/root-reducer'
import { getSelectedSound, playSelectedSound } from '../../sounds'
import { loadContext, makeStudents, pushActivity, saveActivityComent } from './firebase.api'
import { GlobalContext } from './firebase.types'
import StudentsGridHeader from './StudentsGridHeader'

const SOUND_REFRESH_INTERVAL = 30 * 1000 // 30 seconds

export function StudentsGridPageFirebase({ pageContext, toggle = false }: { pageContext: PageContext; toggle?: boolean }) {
  const [context, setContext] = useState<GlobalContext>()

  const [search] = useSearchParams()
  const salas = search.getAll('sala')

  // FETCH INTIAL CONTEXT DATA
  useEffect(() => {
    loadContext().then(setContext)
  }, [])

  if (!context) return null

  return (
    <div>
      <Header />
      <StudentsGridHeader pageContext={pageContext} toggle={toggle} classes={context.classes} ids={salas} />
      <div className="mb-[20px]">
        <ClassDetails ids={salas} pageContext={pageContext} context={context} />
      </div>
    </div>
  )
}

export default function ClassDetails({ ids, pageContext, context }: { ids: string[]; pageContext: PageContext; context: GlobalContext }) {
  const [notificationSettings, setNotificationSettings] = useState(() => getSoundNotificationsState())
  const [currentHiddenStatus, setCurrentHiddenStatus] = useState<StudentStatus[]>(() => getHiddenStatusFromStorage(pageContext))

  const user = useReduxState(state => state.user)
  const loading = useReduxState(state => state.activity.loading)
  const allActivity = useReduxState(state => state.activity.activityItems)
  const activity = allActivity.filter(item => ids.includes(item.class_id))

  // Reset
  useEffect(() => {
    setNotificationSettings(getSoundNotificationsState())
    setCurrentHiddenStatus(getHiddenStatusFromStorage(pageContext))
  }, [ids])

  const currentClasses = context.classes
    .filter(c => ids.includes(c.id))
    .map(
      c =>
        ({
          id: c.id,
          name: c.name,
          shortName: c.shortName,
          students: makeStudents(c, c.students, context, activity)
        } as GetClassResult)
    )

  useInterval(
    () => {
      currentClasses.forEach(currentClass => {
        playSoundIfStatus(notificationSettings.active, currentClass?.students, 'pickup-request')
      })
    },
    notificationSettings.active ? SOUND_REFRESH_INTERVAL : null
  )

  if (loading) return null

  if (ids.length === 0) return null
  const visibleStudents = chain(currentClasses)
    .flatMap(c => c.students)
    .filter(s => !currentHiddenStatus.includes(s.status))
    .orderBy(s => s.name)
    .value()

  function handleToggleActive(isActive: boolean) {
    patchSoundNotificationsState({ active: isActive })
    setNotificationSettings(getSoundNotificationsState())
    currentClasses.forEach(currentClass => {
      playSoundIfStatus(isActive, currentClass?.students, 'pickup-request')
    })
  }

  function handleCellClick(student: Student, targetStatus: StudentStatus) {
    pushActivity(user.email, student, targetStatus)
  }

  function handleSaveComment(student: Student, comment: string) {
    saveActivityComent(user.email, student, comment)
  }

  return (
    <>
      <main
        className={cx('', {
          'pointer-events-none opacity-50': loading
        })}
      >
        <div className="mt-10px">
          <div className="border-t-1 border-b-1 my-[10px] flex items-center gap-[10px] py-[4px] px-[10px]">
            <div className="cursor-pointer"></div>

            <div className="ml-auto flex items-center gap-[10px]">
              <Popup
                position="bottom center"
                trigger={<i className="fa-regular fa-bell" />}
                content={
                  <Popup.Content>
                    <p>
                      Toca o som de uma <strong>{getSelectedSound().name}</strong> sempre que há alunos com pedido de saída. Repete a cada{' '}
                      <strong className="whitespace-nowrap">{SOUND_REFRESH_INTERVAL / 1000} segundos</strong>.
                    </p>
                  </Popup.Content>
                }
              />
              <Checkbox toggle checked={notificationSettings.active} onChange={(_, data) => handleToggleActive(data.checked === true)} />
            </div>
          </div>

          <StudentsTableHeader
            loading={loading}
            header={'Alunos: ' + sortBy(currentClasses.map(c => c.shortName)).join(' / ')}
            hiddenStatus={currentHiddenStatus}
            onToggle={(status, show) => {
              if (show) {
                const newStatus = currentHiddenStatus.filter(s => s !== status)
                setCurrentHiddenStatus(newStatus)
                saveHiddenStatusToStorage(newStatus, pageContext)
              } else {
                const newStatus = [...currentHiddenStatus, status]
                setCurrentHiddenStatus(newStatus)
                saveHiddenStatusToStorage(newStatus, pageContext)
              }
            }}
          />
          <div>
            {visibleStudents.map(student => (
              <div
                key={student.id}
                className={cx(
                  'grid grid-cols-[3fr_1fr_1fr_1fr_1fr] items-center gap-[4px] text-center', //
                  'border-b-1 mx-[10px] border-gray-300 py-[6px] pl-[6px]',
                  'text-px-14',
                  { 'bg-yellow-50': student.mostRecentIndex === 2 },
                  { 'bg-yellow-50': student.mostRecentIndex === 1 },
                  { 'bg-yellow-50': student.mostRecentIndex === 0 }
                )}
              >
                <div className="flex flex-col items-start justify-between overflow-hidden text-ellipsis whitespace-nowrap text-left">
                  <div className="flex w-full items-center justify-between">
                    <span>{student.shortName}</span>
                    {ids.length > 1 && <span className="text-px-10">{currentClasses.find(c => c.id === student.classId)?.shortName}</span>}
                  </div>
                  {<StudentComment student={student} onSaveComment={handleSaveComment} />}
                </div>
                <StatusCell student={student} onClick={handleCellClick} hiddenStatus={currentHiddenStatus} targetStatus={'in-class'} />
                <StatusCell student={student} onClick={handleCellClick} hiddenStatus={currentHiddenStatus} targetStatus={'pickup-request'} />
                <StatusCell student={student} onClick={handleCellClick} hiddenStatus={currentHiddenStatus} targetStatus={'leaving'} />
                <StatusCell student={student} onClick={handleCellClick} hiddenStatus={currentHiddenStatus} targetStatus={'out-of-school'} />
              </div>
            ))}
          </div>
          {visibleStudents.length === 0 && (
            <div className="my-[20px]">
              <Message
                success
                header="INFO"
                content="Nenhum aluno visível para as turmas e/ou estados seleccionados. Mantenha-se atento(a) a esta página e certifique-se que as notificações sonoras estão activas."
              />
            </div>
          )}
        </div>
      </main>
    </>
  )
}

function StudentsTableHeader({
  loading,
  header,
  hiddenStatus = [],
  onToggle
}: {
  loading: boolean
  header?: string
  hiddenStatus?: StudentStatus[]
  onToggle?: (status: StudentStatus, show: boolean) => any
}) {
  function handleToggle(status: StudentStatus) {
    if (!onToggle) return
    const isHidden = hiddenStatus.includes(status)
    onToggle(status, isHidden ? true : false)
  }
  return (
    <div
      className={cx(
        'grid grid-cols-[3fr_1fr_1fr_1fr_1fr] items-center gap-[4px] text-center', //
        'border-b-1 border-gray-300 py-[6px] pl-[6px]',
        'bg-slate-400',
        'font-extrabold text-white',
        { 'pointer-events-none select-none opacity-50': loading }
      )}
    >
      <div className="flex flex-col text-left">
        {header || ''}
        <small className="text-px-8 font-normal">
          Clique nos icons ao lado para <br />
          mostrar/esconder alunos nesse estado
        </small>
      </div>

      <StudentsTableHeaderIcon icon={faPersonChalkboard} text="Em Sala" targetStatus="in-class" handleToggle={handleToggle} hiddenStatus={hiddenStatus} />
      <StudentsTableHeaderIcon
        icon={faPersonWalkingDashedLineArrowRight}
        text="Pedído Saída"
        targetStatus="pickup-request"
        handleToggle={handleToggle}
        hiddenStatus={hiddenStatus}
      />

      <StudentsTableHeaderIcon icon={faPersonWalkingLuggage} text="A Sair" targetStatus="leaving" handleToggle={handleToggle} hiddenStatus={hiddenStatus} />
      <StudentsTableHeaderIcon icon={faPersonCircleMinus} text="Fora" targetStatus="out-of-school" handleToggle={handleToggle} hiddenStatus={hiddenStatus} />
    </div>
  )
}

function StudentsTableHeaderIcon(props: {
  icon: IconProp
  text: string
  hiddenStatus: StudentStatus[]
  targetStatus: StudentStatus
  handleToggle: (status: StudentStatus) => any
}) {
  return (
    <div
      className={cx('cursor-pointer', {
        'opacity-50': props.hiddenStatus.includes(props.targetStatus)
      })}
      onClick={() => props.handleToggle(props.targetStatus)}
    >
      <FontAwesomeIcon icon={props.icon} />
      <div className="text-px-10">{props.text}</div>
    </div>
  )
}

function StatusCell({
  student,
  targetStatus,
  hiddenStatus,
  onClick
}: {
  student: Student
  hiddenStatus: StudentStatus[]
  targetStatus: StudentStatus
  onClick: (student: Student, targetStatus: StudentStatus) => any
}) {
  const isActive = targetStatus.includes(student.status)
  const isDisabled = hiddenStatus.includes(targetStatus)

  async function handleClick() {
    if (isDisabled) {
      if (
        !window.confirm(`Tem a certeza que quer mudar o estado do aluno?


${student.shortName} -> ${statusToName(targetStatus)}


Nota: Ele vai desaparecer da listagem pois este estado (${statusToName(targetStatus)}) está escondido.
`)
      )
        return
    }
    if (!isActive) onClick(student, targetStatus)
  }

  // if (isDisabled) return <div className="border-1 border-hex-979797 flex items-center justify-center self-stretch border-dotted py-[5px] text-gray-700 "></div>
  return (
    <div
      onClick={handleClick}
      className={cx(
        'flex items-center justify-center self-stretch py-[5px] text-gray-700 ', //
        {
          'cursor-pointer': true,
          'bg-gray-100': isDisabled,
          'bg-gray-200': !isDisabled && !isActive,
          'bg-green-100': isActive && targetStatus === 'in-class',
          'bg-green-200': isActive && targetStatus === 'pickup-request',
          'bg-green-300': isActive && targetStatus === 'leaving',
          'bg-green-400': isActive && targetStatus === 'out-of-school'
        }
      )}
    >
      {isActive && student.status !== 'in-class' && <span className="text-px-8">{moment(student.statusDate).format('HH[h]mm[m]')}</span>}
      {!isActive && <span>&nbsp;</span>}
      {/* {hiddenStatus.includes(targetStatus) && <small className="text-[6px] text-gray-400">{targetStatus}</small>} */}
    </div>
  )
}

function playSoundIfStatus(isActive: boolean, students: Student[] = [], status: StudentStatus = 'pickup-request') {
  // console.log('%s: PLAY SOUND IF STATUS', new Date().toISOString())
  if (!isActive) return
  const hasPickup = students.some(s => s.status === status)
  if (hasPickup) playSelectedSound()
}
