import { StudentStatus } from '../api'

export function statusToName(status: StudentStatus) {
  switch (status) {
    case 'in-class':
      return 'Em Sala'
    case 'pickup-request':
      return 'Pedido de Saída'
    case 'leaving':
      return 'Saída de Sala'
    case 'out-of-school':
      return 'Fora'
    default:
      return status
  }
}
