import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// @ts-ignore
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import { AdminClassesPage } from './admin/AdminClassesPage'
import { AdminHomePage } from './admin/AdminHomePage'
import { AdminNewStudentPage } from './admin/AdminNewStudentPage'
import { AdminStudentsPage } from './admin/AdminStudentsPage'
import { AdminUsersPage } from './admin/AdminUsersPage'
import { API } from './api'
import { LoadingIndicator } from './components/LoadingIndicator'
import { OnlineOfflineIndicator } from './components/OnlineOfflineIndicator'
import { PageContext } from './core/PageContext'
import { HomePage } from './HomePage'
import { ActivityPage } from './pages/activity/ActivityPage'
import { LoginPage } from './pages/auth/LoginPage'
import { LogoutPage } from './pages/auth/LogoutPage'
import { initFirebase } from './pages/students-grid/firebase.api'
import { StudentsGridPageFirebase } from './pages/students-grid/StudentsGridPageFirebase'
import { startActivityListener } from './reducers/activity-reducer'
import { useReduxState } from './reducers/root-reducer'
import { userSlice } from './reducers/user-reducer'

function App() {
  const dispatch = useDispatch()
  const user = useReduxState(s => s.user)
  useEffect(() => {
    async function getUser() {
      const result = await API.getEmployee()
      if (!result) return
      dispatch(
        userSlice.actions.setUser({
          name: result.user.name,
          email: result.user.email,
          role: result.user.role,
          loggedIn: true
        })
      )
      initFirebase(result.firebase.config, result.firebase.auth.email, result.firebase.auth.password)
      startActivityListener(dispatch)
    }
    getUser()
  }, [dispatch])

  return (
    <div className="m-auto max-w-[534px] select-none">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/" element={<HomePage />} />

          {/* USER ROUTES */}
          {user.loggedIn && <Route path="/gate" element={<StudentsGridPageFirebase pageContext={PageContext.Portão} />} />}
          {user.loggedIn && <Route path="/klass" element={<StudentsGridPageFirebase pageContext={PageContext.SalaDeAula} />} />}
          {user.loggedIn && <Route path="/pickup" element={<StudentsGridPageFirebase pageContext={PageContext.PedidosDeSaída} toggle />} />}
          {user.loggedIn && <Route path="/leaving" element={<StudentsGridPageFirebase pageContext={PageContext.ASair} toggle />} />}

          {user.loggedIn && <Route path="/firebase" element={<StudentsGridPageFirebase pageContext={PageContext.PedidosDeSaída} toggle />} />}

          {/* EMPLOYEE ROUTES */}
          {user.loggedIn && ['employee', 'admin'].includes(user.role) && <Route path="/actividade" element={<ActivityPage />} />}

          {/* ADMIN ROUTES */}
          {user.loggedIn && ['admin'].includes(user.role) && <Route path="/admin" element={<AdminHomePage />} />}
          {user.loggedIn && ['admin'].includes(user.role) && <Route path="/admin/users" element={<AdminUsersPage />} />}
          {user.loggedIn && ['admin'].includes(user.role) && <Route path="/admin/classes" element={<AdminClassesPage />} />}
          {user.loggedIn && ['admin'].includes(user.role) && <Route path="/admin/students" element={<AdminStudentsPage />} />}
          {user.loggedIn && ['admin'].includes(user.role) && <Route path="/admin/students/new" element={<AdminNewStudentPage />} />}
        </Routes>
        <OnlineOfflineIndicator />
        <LoadingIndicator />
      </Router>
    </div>
  )
}

export default App
