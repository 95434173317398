import React, { useEffect, useState } from 'react'
import { Select } from 'semantic-ui-react'
import { API, GetClassResult, StudentAdmin } from '../api'
import { Header } from '../components/Header'
import { HeaderTitle } from '../components/HeaderTitle'

export function AdminClassesPage() {
  const [loading, setLoading] = useState(false)
  const [classes, setClasses] = useState([] as GetClassResult[])
  const [students, setStudents] = useState([] as StudentAdmin[])
  useEffect(() => {
    ;(async function () {
      setLoading(true)
      const classes = await API.getClasses()
      setClasses(classes)
      const students = await API.admin.getStudents()
      setStudents(students)
      setLoading(false)
    })()
  }, [])

  async function handleSaveClassStudents(classId: string, ids: string[]) {
    setLoading(true)
    document.getElementById('wrapper')?.scrollIntoView({ behavior: 'smooth' })
    await API.admin.setClassStudents(classId, ids)
    const classes = await API.getClasses()
    setClasses(classes)
    setLoading(false)
  }

  return (
    <div>
      <Header />
      <HeaderTitle content="Administração / Turmas" loading={loading} />
      <ClassesTable
        classes={classes.filter(c => c)}
        students={students}
        onSaveClassStudents={handleSaveClassStudents}
      />
    </div>
  )
}

function ClassesTable({
  classes,
  students,
  onSaveClassStudents
}: {
  classes: GetClassResult[]
  students: StudentAdmin[]
  onSaveClassStudents: (classId: string, ids: string[]) => any
}) {
  const [selectedClass, setSelectedClass] = useState(null as GetClassResult | null)
  const [classStudents, setClassStudents] = useState([] as StudentAdmin[])
  function selectClass(klass: GetClassResult) {
    setSelectedClass(klass)
    setClassStudents(klass.students.map(s => students.find(ss => ss.id === s.id) as StudentAdmin).filter(s => s))
  }
  function handleClassStudentChanged(ids: string[]) {
    setClassStudents(ids.map(id => students.find(s => s.id === id) as StudentAdmin))
  }
  return (
    <div>
      <table className="ui very compact striped unstackable selectable table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Alunos</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {classes.map(c => (
            <tr key={c.id} className={c === selectedClass ? 'positive' : ''}>
              <td>{c.id}</td>
              <td>{c.name}</td>
              <td>{c.students.length}</td>
              <td>
                <button className="ui very small button" onClick={() => selectClass(c)}>
                  Seleccionar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedClass && (
        <div className="pt-px-50">
          <h2 className="flex items-center justify-between">
            <span className="text-px-24 font-normal">
              Alunos da turma <span className="font-extrabold">[{selectedClass.name}]</span>
            </span>
          </h2>
          <Select
            multiple
            fluid
            search
            value={classStudents.map(s => s.id)}
            options={students
              .filter(s => s.klasses.length === 0 || s.klasses.findIndex(k => k.id === selectedClass.id) !== -1)
              .map((s, i) => ({
                key: s.id,
                text: `[${(classStudents.findIndex(css => css.id === s.id) + 1).toFixed().padStart(2, ' ')}] ${
                  s.id
                } - ${s.shortName} (${s.name.substring(0, 25)}...)`,
                value: s.id
              }))}
            onChange={(_, data) => handleClassStudentChanged(data.value as string[])}
          />
          <div className="pt-px-20 flex justify-end">
            <button
              className="ui primary button very small basic"
              onClick={() =>
                onSaveClassStudents(
                  selectedClass.id,
                  classStudents.map(s => s.id)
                )
              }
            >
              GRAVAR
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
