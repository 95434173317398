import { createSlice } from '@reduxjs/toolkit'

const initialState: OnlineStatus = {
  isOnline: true
}

export const onlineSlice = createSlice({
  name: 'online',
  initialState,
  reducers: {
    setOnline: state => ({ ...state, isOnline: true }),
    setOffline: state => ({ ...state, isOnline: false })
  }
})

type OnlineStatus = {
  isOnline: boolean
}
