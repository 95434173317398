import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onlineSlice } from '../reducers/online-reducer'
import { useReduxState } from '../reducers/root-reducer'

export function OnlineOfflineIndicator() {
  const isOnline = useReduxState(state => state.online.isOnline)
  const dispatch = useDispatch()

  useEffect(() => {
    const setOnline = () => dispatch(onlineSlice.actions.setOnline())
    const setOffline = () => dispatch(onlineSlice.actions.setOffline())
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  })

  if (isOnline) return null

  return (
    <div className="py-px-30 bg-c-red fixed top-1/2 left-0 z-50 flex w-full items-center justify-center font-extrabold text-white">
      <span>
        <strong>OFFLINE: </strong> Verifique a sua ligação à internet.
      </span>
    </div>
  )
}
