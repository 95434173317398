import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { cx } from '../core/cx'

export function HeaderTitle({
  content,
  loading = false,
  light = false,
  className = ''
}: {
  content: string
  loading?: boolean
  light?: boolean
  className?: string
}) {
  return (
    <div
      className={cx(
        'my-[10px] flex justify-between ',
        'border-b-1 border-gray-300 py-[6px] px-[6px]',
        {
          'bg-slate-400': light,
          'bg-slate-500': !light
        },
        'font-extrabold text-white',
        className
      )}
    >
      <span>{content}</span>
      {loading && (
        <span className="text-c-lemon flex items-center gap-[5px]">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </span>
      )}
    </div>
  )
}
