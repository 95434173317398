import classNames from 'classnames'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Header } from '../components/Header'
import { HeaderTitle } from '../components/HeaderTitle'
import { createStudent, readAllStudentsWithClass } from '../pages/students-grid/firebase.api'
import { shortName } from '../shortName'

export function AdminNewStudentPage() {
  async function handleCreateStudent(id: string, name: string) {
    const allStudents = await readAllStudentsWithClass()
    if (allStudents.find(s => s.student.id === id)) return { ok: false, error: 'Aluno com este ID já existe!' }

    await createStudent(id, name)
    return { ok: true }
  }

  return (
    <div>
      <Header />
      <HeaderTitle content="Administração / Criar Aluno" />
      <NewStudentForm onCreateStudent={handleCreateStudent} />
    </div>
  )
}

function NewStudentForm({
  onCreateStudent
}: {
  errorMessage?: string
  success?: boolean
  onCreateStudent: (id: string, name: string) => Promise<{ ok: boolean; error?: string }>
}) {
  const [loading, setLoading] = useState(false)
  const [studentCreated, setStudentCreated] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [id, setId] = useState('')
  const [name, setName] = useState('')

  function validateForm() {
    if (id && !id.startsWith('s')) {
      return 'ID do aluno tem que começar com a letra "s"!'
    }
    if (id && id.includes(' ')) {
      return 'ID do aluno não pode ter espaços!'
    }
    if (id && id.length !== 10) {
      return 'ID do aluno tem que ter 10 caracteres!'
    }
    if (name && name.length < 3) {
      return 'Nome do aluno tem que ter pelo menos 3 caracteres!'
    }
    if (name && !name.includes(' ')) {
      return 'Nome do aluno tem que ter pelo menos dois nomes!'
    }
  }

  async function handleCreateStudent(id: string, name: string) {
    const error = validateForm()
    if (error) {
      return setErrorMessage(error)
    }

    setLoading(true)
    try {
      const res = await onCreateStudent(id, name)
      if (res.ok) {
        setStudentCreated(true)
        setErrorMessage('')
      } else {
        setStudentCreated(false)
        setErrorMessage(res.error || 'Erro desconhecido')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="ui segment">
      <h1 className="text-px-20 pb-px-20 font-extrabold">CRIAR NOVO ALUNO</h1>
      <form className="ui form">
        <div className="ui field group">
          <div className="ui field">
            <label>
              ID <small className="font-light">(s seguido de 9 digitos)</small>
            </label>
            <input required maxLength={10} placeholder="s11111111" value={id} onChange={evt => setId(evt.target.value)} />
          </div>
          <div className="ui field">
            <label>
              Nome Completo <small className="font-light">(pelo menos dois nomes)</small>
            </label>
            <input required placeholder="Nome completo do aluno" value={name} onChange={evt => setName(evt.target.value)} />
          </div>
          <div className="ui field">
            <label>
              Nome Curto <small className="font-light">(gerado automaticamente)</small>
            </label>
            <input disabled placeholder="Nome curto (automático)" value={shortName(name)} />
          </div>
        </div>
        <div className="gap-px-10 flex items-center justify-end">
          {errorMessage && <span className="text-c-red">{errorMessage}</span>}
          {studentCreated && (
            <span className="text-primary">
              Aluno criado com sucesso. Edite a turma{' '}
              <Link className="underline" to="/admin/students">
                aqui
              </Link>
              .
            </span>
          )}
          <button
            className={classNames('ui primary very basic button', { loading: loading })}
            disabled={loading || !id || !name}
            onClick={evt => {
              evt.preventDefault()
              handleCreateStudent(id, name)
            }}
          >
            CRIAR
          </button>
        </div>
      </form>
    </div>
  )
}
