import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { API } from '../../api'
import { useReduxState } from '../../reducers/root-reducer'
import { userSlice } from '../../reducers/user-reducer'

export function LoginPage() {
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()

  const user = useReduxState(s => s.user)
  if (user.loggedIn) {
    window.location.assign('/')
    return null
  }

  async function handleSubmit() {
    const user = await API.login(email, password)
    if (!user) return setError('Login error. Try again.')

    // Login
    dispatch(
      userSlice.actions.setUser({
        name: user.name,
        email: user.email,
        role: user.role,
        loggedIn: true
      })
    )
    setTimeout(() => {
      window.location.href = '/'
    }, 100)
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="pink" textAlign="center">
          <Link to="/" style={{ display: 'inline-block' }}>
            <Image src="/Logo_APEE.jpg" style={{ width: 300 }} />
          </Link>
        </Header>
        <Form size="large" error={!!error} onSubmit={handleSubmit}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              type="email"
              autoFocus
              iconPosition="left"
              placeholder="E-mail"
              value={email}
              onChange={(_, { value }) => setEmail(value.toLowerCase().trim())}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(_, { value }) => setPassword(value)}
            />
            <Button color="pink" fluid size="large">
              Entrar
            </Button>
          </Segment>
          <Message error>{error}</Message>
        </Form>
      </Grid.Column>
    </Grid>
  )
}
