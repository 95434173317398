import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { API, Employee } from '../api'
import { Header } from '../components/Header'
import { HeaderTitle } from '../components/HeaderTitle'

export function AdminUsersPage() {
  const d: Employee[] = []
  const [loading, setLoading] = useState(false)
  const [employees, setEmployees] = useState(d)
  useEffect(() => {
    setLoading(true)
    API.admin
      .getEmployees()
      .then(setEmployees)
      .then(() => setLoading(false))
  }, [])

  return (
    <div>
      <Header />
      <HeaderTitle content="Administração / Utilizadores" loading={loading} />
      <EmployeeTable type="Administrador" employees={employees.filter(e => e.role === 'admin')} />
      <EmployeeTable type="Funcionário" employees={employees.filter(e => e.role === 'employee')} />
    </div>
  )
}

function EmployeeTable({ type, employees }) {
  return (
    <div className="xui segment">
      <HeaderTitle content={type} light />
      <table className="ui very compact striped unstackable !text-px-10 table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Nome</th>
            <th>Acções</th>
          </tr>
        </thead>
        <tbody>
          {employees.map(e => (
            <EmployeeRow employee={e} key={e.email} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

function EmployeeRow({ employee }: { employee: Employee }) {
  const [editmode, setEditMode] = useState(false)
  const [pwd, setPwd] = useState('')

  if (editmode) {
    return (
      <tr>
        <td>{employee.email}</td>
        <td colSpan={2}>
          <form className="flex justify-between">
            <input type="password" className="!text-px-10 border-1 rounded-md px-[4px]" required value={pwd} onChange={evt => setPwd(evt.target.value)} />
            <div className="ml-auto">
              <Button
                size="small"
                primary
                className="!text-px-10"
                disabled={pwd === ''}
                content={<FontAwesomeIcon icon={faSave} />}
                onClick={async evt => {
                  API.admin.setEmployeePassword(employee.email, pwd)
                  setEditMode(false)
                  evt.preventDefault()
                }}
              />
              <Button className="!text-px-10" size="small" color="grey" content="Cancel" onClick={() => setEditMode(false)} />
            </div>
          </form>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td>{employee.email}</td>
      <td>{employee.name}</td>
      <td className="flex justify-end">
        <Button className="!text-px-10" secondary size="small" basic content="Password" onClick={() => setEditMode(true)} />
      </td>
    </tr>
  )
}
