import { FirebaseOptions } from 'firebase/app'

export type Gender = 'M' | 'F'

export type StudentAdmin = {
  id: string
  name: string
  shortName: string
  klasses: { id: string; name: string }[]
}

export type Student = {
  key: string
  id: string
  name: string
  shortName: string
  classId: string
  gender: Gender
  klass: {
    id: string
    name: string
  }
  statusKey?: string
  status: StudentStatus
  mostRecentIndex?: number
  statusDate?: string
  statusDateMs?: number
  check: boolean
  comment?: string
}
export type StudentStatus = 'in-class' | 'pickup-request' | 'leaving' | 'out-of-school'
export type GetClassResult = {
  id: string
  name: string
  shortName: string
  lastActivityDateMs?: number
  students: Student[]
}
export type Employee = {
  name: string
  email: string
  role: 'guest' | 'admin' | 'employee'
}
export type GetLightClassResult = {
  id: string
  name: string
  shortName: string
}

export const API = {
  getClass: async (id: string) => {
    const result: GetClassResult = await fetch(`/api/classes/${id}`).then(res => res.json())
    return result
  },
  getLightClasses: async () => {
    const result: GetLightClassResult[] = await fetch(`/api/classes?light=true`).then(res => res.json())
    return result
  },
  getClasses: async () => {
    const result: GetClassResult[] = await fetch(`/api/classes`).then(res => res.json())
    return result
  },

  getStudents: async (filterName = '', filterClass = '', filterStatus = '') => {
    const result: Student[] = await fetch(`/api/students?name=${filterName}&klass=${filterClass}&status=${filterStatus}`).then(res => res.json())
    return result
  },

  getClassActivityLogSince: async (classId: string, since = 0) => {
    const result: any[] = await fetch(`/api/activity?classId=${classId}&since=${since}`).then(res => res.json())
    return result
  },

  getActivityLog: async () => {
    const activity = await fetch(`/api/activity`).then(res => res.json())
    return activity
  },

  addActivityLog: async (clientID, studentId: string, classId: string, action: StudentStatus) => {
    await fetch(`/api/activity/classes/${classId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ studentId, classId, action, clientID })
    })
  },

  toggleLatestActivityCheck: async (studentId: string) => {
    await fetch(`/api/activity/students/${studentId}/latest/mark`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ studentId })
    })
  },

  setActivityComment: async (studentId: string, classId: string, comment: string) => {
    await fetch(`/api/activity/students/${studentId}/latest/comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ studentId, classId, comment })
    })
  },

  login: async (email: string, password: string) => {
    const res = await fetch(`/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    })
    if (res.ok) {
      const user = await res.json()
      return user as Employee
    }
    return null
  },

  logout: async () => {
    await fetch(`/api/logout`)
  },

  getEmployee: async () => {
    const res = await fetch(`/api/user`)
    if (res.ok) {
      const payload = await res.json()
      return payload as { user: Employee; firebase: { config: FirebaseOptions; auth: { email: string; password: string } } }
    }
    return null
  },

  admin: {
    getEmployees: async () => {
      const result: Employee[] = await fetch(`/api/admin/employees`).then(res => res.json())
      return result
    },
    setEmployeePassword: async (email: string, pwd: string) => {
      await fetch(`/api/admin/employees/${email}/password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password: pwd })
      })
    },

    getStudents: async () => {
      const result: StudentAdmin[] = await fetch(`/api/admin/students`).then(res => res.json())
      return result
    },
    removeStudent: async (id: string) => {
      await fetch(`/api/admin/students/${id}`, {
        method: 'DELETE'
      })
    },
    createStudent: async (id: string, name: string) => {
      await fetch(`/api/admin/students`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: id.trim(), name: name.trim() })
      })
    },
    setClassStudents: async (classId: string, ids: string[]) => {
      await fetch(`/api/admin/classes/${classId}/students`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: ids })
      })
    }
  }
}
