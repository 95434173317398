import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Input, Label, Modal, Popup } from 'semantic-ui-react'
import { Student } from '../api'
import { useReduxState } from '../reducers/root-reducer'

export function StudentComment({
  student,
  onSaveComment,
  onStartEdit,
  onEndEdit
}: {
  student: Student
  onSaveComment?: (student: Student, comment: string) => any
  onStartEdit?: () => any
  onEndEdit?: () => any
}) {
  const [input, setInput] = useState('')
  const [comment, setComment] = useState(student.comment)
  const [visibleModal, setVisibleModal] = useState(false)
  const user = useReduxState(state => state.user)

  useEffect(() => {
    setComment(student.comment)
    setVisibleModal(false)
  }, [student])

  function handleSave() {
    endEdit()
    onSaveComment && onSaveComment(student, comment || '')
  }

  function startEdit() {
    onStartEdit && onStartEdit()
    setVisibleModal(true)
  }

  function endEdit() {
    onEndEdit && onEndEdit()
    setVisibleModal(false)
  }

  function appendComment(newComment: string) {
    setComment(`${comment || ''}${comment ? '\n' : ''}[${user.name} - ${moment().format('LT')}] ${newComment}`)
  }

  return (
    <>
      <Modal
        open={visibleModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnTriggerBlur={false}
        closeIcon
        onClose={() => setVisibleModal(false)}
      >
        <Modal.Header>Comentário para: {student.shortName}</Modal.Header>
        <Modal.Content>
          <div className="gap-px-10 mb-px-10 flex">
            <Input value={input} fluid onChange={e => setInput(e.target.value)} className="text-px-13 flex-grow" />
            <Button
              content="Adicionar"
              disabled={input === ''}
              className="w-px-100"
              onClick={() => {
                appendComment(input)
                setInput('')
              }}
            />
          </div>
          <Label.Group>
            <Label content="ACTIVIDADE" onClick={() => appendComment('ACTIVIDADE')} />
            <Label content="CAMPO" onClick={() => appendComment('CAMPO')} />
          </Label.Group>

          <textarea
            className="p-px-10 border-1 border-hex-4A4A4A bg-c-oat w-full rounded-md"
            rows={5}
            readOnly
            value={comment || ''}
            onChange={e => setComment(e.target.value)}
          />
          <div></div>
        </Modal.Content>
        <Modal.Actions
          actions={[
            { key: 'reset', content: 'Limpar', secondary: true, onClick: () => setComment('') },
            { key: 'cancel', content: 'Cancelar', onClick: endEdit },
            { key: 'done', content: 'Gravar', disabled: input !== '', positive: true, onClick: handleSave }
          ]}
        />
      </Modal>

      <span className="text-px-8 mr-[10px]  text-gray-500" title={student.comment} onClick={startEdit}>
        <FontAwesomeIcon icon={faComment} color="#CECECE" />{' '}
        {student.comment && (
          <Popup position="top center" trigger={<span>{student.comment.substring(0, 40)}...</span>}>
            <pre className="text-px-10">{student.comment}</pre>
          </Popup>
        )}
      </span>

      {/* {!student.comment && onSaveComment && (
        <i className="fa-regular text-hex-E3E3E3 fa-comment ml-px-4" onClick={startEdit} />
      )} */}

      {/* {student.comment && (
        <Popup position="top center" trigger={<i className="fa-solid text-hex-3750A0 fa-comment ml-px-4" />}>
          {student.comment}
          {onSaveComment && <i className="fa-solid fa-pencil ml-px-10" onClick={startEdit} />}
        </Popup>
      )} */}
    </>
  )
}
