import React from 'react'
import { Link } from 'react-router-dom'
import { Header } from '../components/Header'
import { HeaderTitle } from '../components/HeaderTitle'

export function AdminHomePage() {
  return (
    <div>
      <Header />
      <HeaderTitle content="Administração" />
      <ul className="gap-px-10 mt-[10px] flex">
        <Link to="/admin/users" className="border-1 border-hex-858585 h-px-49 w-px-192 hover:bg-hex-858585 flex items-center justify-center hover:text-white">
          Utilizadores
        </Link>
        <Link
          to="/admin/students"
          className="border-1 border-hex-858585 h-px-49 w-px-192 hover:bg-hex-858585 flex items-center justify-center hover:text-white"
        >
          Alunos
        </Link>
        <Link
          to="/admin/students/new"
          className="border-1 border-hex-858585 h-px-49 w-px-192 hover:bg-hex-858585 flex items-center justify-center hover:text-white"
        >
          Novo Aluno
        </Link>
      </ul>
    </div>
  )
}
