/**
 * SOUNDS LIBRARY
 *
 * Source: https://bigsoundbank.com
 *
 * Note: buffers automatically when created
 */

import { getSoundNotificationsState, patchSoundNotificationsState } from './core/local-storage.service'

const s_doorbell1 = new Audio('/sounds/doorbell1.wav')
const s_horn1 = new Audio('/sounds/horn1.wav')
const s_partyhorn4 = new Audio('/sounds/partyhorn4.wav')
const s_happybirthday = new Audio('/sounds/happybirthday.wav')
const s_battery6 = new Audio('/sounds/battery6.wav')
const s_applause1 = new Audio('/sounds/applause1.wav')

export const Sounds: SoundConfig[] = [
  { name: 'Campainha', audio: s_doorbell1 },
  { name: 'Buzina', audio: s_horn1 },
  { name: 'Corneta', audio: s_partyhorn4 },
  { name: 'Bateria', audio: s_battery6 },
  { name: 'Aplauso', audio: s_applause1 },
  { name: 'Feliz Aniversário', audio: s_happybirthday }
]

export async function playSelectedSound() {
  return getSelectedSound()
    .audio.play()
    .catch(err => {
      console.error('Error playing the sound.', err)
    })
}

type SoundConfig = {
  name: string
  audio: HTMLAudioElement
}

export function setSelectedSound(sound: SoundConfig) {
  patchSoundNotificationsState({ soundName: sound.name })
  return playSelectedSound()
}

export function isSoundSelected(sound: SoundConfig) {
  const selected = getSelectedSound()
  return selected.name === sound.name
}

export function getSelectedSound() {
  const sound = Sounds.find(s => s.name === getSoundNotificationsState().soundName)
  if (sound) return sound
  return Sounds[0]
}
