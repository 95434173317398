import { faChalkboard, faCog, faPersonChalkboard, faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { cx } from '../core/cx'
import { useReduxState } from '../reducers/root-reducer'

export function Header() {
  const user = useReduxState(state => state.user)
  const [search] = useSearchParams()

  return (
    <div className="border-b-1 border-b-hex-858585 flex flex-row items-center gap-[2px] py-[2px] px-[10px]">
      <MenuItem visible to="/" className="">
        <img src="/Logo_APEE.jpg" alt="APEE LOGO" width={60} height={60 / 2} />
      </MenuItem>

      <MenuItem className=" ml-[10px] min-w-[20px]" visible={user.loggedIn} to={`/gate?${search.toString()}`}>
        <FontAwesomeIcon icon={faPersonChalkboard} size="1x" /> Portão
      </MenuItem>
      <MenuItem className=" ml-[10px] min-w-[20px]" visible={user.loggedIn} to={`/pickup?${search.toString()}`}>
        <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} size="1x" /> Saídas
      </MenuItem>
      <MenuItem className=" ml-[10px] min-w-[20px]" visible={user.loggedIn} to={`/klass?${search.toString()}`}>
        <FontAwesomeIcon icon={faChalkboard} size="1x" /> Sala
      </MenuItem>
      {/* <MenuItem className="ml-[10px]" visible={user.loggedIn} to="/klass">
        <FontAwesomeIcon icon={faChalkboardTeacher} size="1x" /> Sala
      </MenuItem> */}
      {/* <MenuItem className="ml-auto min-w-[20px]" visible={user.loggedIn} to="/actividade">
        <FontAwesomeIcon icon={faIndent} size="1x" />
      </MenuItem>*/}

      {/* SPACER */}
      <div className="ml-auto"></div>
      <MenuItem className="min-w-[20px]" visible={user.loggedIn && user.role === 'admin'} to="/admin">
        <FontAwesomeIcon icon={faCog} size="1x" />
      </MenuItem>

      <MenuItem to="/login" visible={!user.loggedIn} className="">
        Entrar
      </MenuItem>

      {/* LOGIN / LOGOUT */}
      <MenuItem visible={user.loggedIn} className="flex flex-row items-center">
        <div className="flex flex-col items-end gap-[2x]">
          <span className="whitespace-nowrap">
            Olá <strong>{user.name.split(' ')[0]}!</strong>
          </span>
          <a href="/logout" className="text-px-12">
            Sair
          </a>
        </div>
      </MenuItem>
    </div>
  )
}

function MenuItem({
  to,
  visible = false,
  className = '',
  onClick = () => {},
  children
}: {
  to?: string
  className?: string
  visible?: boolean
  onClick?: () => any
  children: React.ReactNode
}) {
  if (!visible) return null
  if (!to) {
    return <span className={cx('flex min-w-[45px] items-center justify-center gap-[4px] self-stretch', className)}>{children}</span>
  }
  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={({ isActive }) =>
        cx(
          'flex min-w-[45px] items-center justify-center gap-[4px] self-stretch',
          'hover:text-pink-500',
          {
            'text-pink-500': isActive
          },
          className
        )
      }
    >
      {children}
    </NavLink>
  )
}
