import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { child, get, getDatabase, onChildAdded, orderByKey, push, query, ref, set, startAfter } from 'firebase/database'
import { orderBy } from 'lodash'
import { Student, StudentStatus } from '../../api'
import { shortName } from '../../shortName'
import { FirebaseActivityItem, FirebaseClass, FirebaseClasses, FirebaseStudent, FirebaseStudents, GlobalContext } from './firebase.types'

let app: FirebaseApp

export function initFirebase(config: FirebaseOptions, loginEmail: string, loginPwd: string) {
  app = initializeApp(config)

  // Login
  const auth = getAuth(app)
  signInWithEmailAndPassword(auth, loginEmail, loginPwd)
  onAuthStateChanged(auth, user => {
    console.log('AUTH CHANGED', user)
  })
}

export async function logoutFirebase() {
  if (!app) return
  return getAuth(app).signOut()
}

export async function loadContext(): Promise<GlobalContext | undefined> {
  const db = getDatabase(app)
  const classesSnap = await get(child(ref(db), `classes`))
  const studentsSnap = await get(child(ref(db), `students`))
  if (!classesSnap.exists() || !studentsSnap.exists()) return undefined
  return {
    classes: orderBy(Object.values(classesSnap.val()), 'order'),
    students: studentsSnap.val()
  }
}

export async function readAllActivity(): Promise<[FirebaseActivityItem[], string | null]> {
  const db = getDatabase(app)
  const now = new Date() // new Date('2023-03-22')
  const today = now.toISOString().substring(0, 10)

  const snapshot = await get(child(ref(db), activityPath(today)))
  if (!snapshot.exists()) return [[], null]
  const entries = Object.entries(snapshot.val())
  const items = entries.map(([key, item]) => item as FirebaseActivityItem)
  const lastKey = entries.length > 0 ? entries[entries.length - 1][0] : null
  // console.log('READ ACTIVITY ITEMS', items, 'WITH LAST KEY = ', lastKey)
  return [items, lastKey]
}

export function onActivity(lastKey: string | null, setActivity: (activityItem: FirebaseActivityItem) => any) {
  const db = getDatabase(app)
  const now = new Date()
  const today = now.toISOString().substring(0, 10)
  const todayActivityRef = ref(db, activityPath(today))
  const newActivityRef = lastKey ? query(todayActivityRef, orderByKey(), startAfter(lastKey)) : todayActivityRef

  return onChildAdded(newActivityRef, snapshot => {
    if (!snapshot.exists()) return
    const item = snapshot.val() as FirebaseActivityItem
    // console.log('%d ON ACTIVITY ITEM', i++, item)
    setActivity(item)
  })
}

export async function pushActivity(employeeEmail: string, student: Student, targetStatus: StudentStatus) {
  const db = getDatabase(app)
  const now = new Date()
  const today = now.toISOString().substring(0, 10)

  const activity = {
    when: now.toISOString(),
    student_id: student.id,
    action: targetStatus,
    class_id: student.classId,
    employee_id: employeeEmail,
    comment: student.comment
  }

  const key = push(child(ref(db), activityPath(today))).key
  set(ref(db, activityPath(today, key)), activity)
}

export async function saveActivityComent(employeeEmail: string, student: Student, comment: string) {
  const db = getDatabase(app)
  const now = new Date()
  const today = now.toISOString().substring(0, 10)

  const activity = {
    when: now.toISOString(),
    student_id: student.id,
    action: student.status,
    class_id: student.classId,
    employee_id: employeeEmail,
    comment: comment
  }

  const key = push(child(ref(db), activityPath(today))).key
  set(ref(db, activityPath(today, key)), activity)
}

function activityPath(isoDay: string, child?: string | null) {
  const path = `activity/${isoDay}`
  if (!child) return path
  return `${path}/${child}`
}

/**
 * Read all classes from the database from the last year configured
 */
export async function readAllClasses(): Promise<FirebaseClass[]> {
  const db = getDatabase(app)
  const dbYears = await get(child(ref(db), 'year'))
  const years = dbYears.val() as Record<string, Record<string, boolean>>
  const lastYear = Object.keys(years).sort().pop()

  const snapshot = await get(child(ref(db), 'classes'))
  if (!snapshot.exists()) return []
  return (Object.values(snapshot.val()) as FirebaseClass[]).filter(c => c.year === lastYear)
}

export type FirebaseStudentWithClass = {
  student: FirebaseStudent
  klassId: string | undefined
}
export async function readAllStudentsWithClass(): Promise<FirebaseStudentWithClass[]> {
  const db = getDatabase(app)

  const studentsSnapshot = await get(child(ref(db), 'students'))
  if (!studentsSnapshot.exists()) return []
  const studentsSnapshotVal = studentsSnapshot.val() as FirebaseStudents

  const classesSnapshot = await get(child(ref(db), 'classes'))
  const classesSnapshotVal = classesSnapshot.val() as FirebaseClasses

  return Object.values(studentsSnapshotVal).map(student => {
    const klassId = Object.entries(classesSnapshotVal).find(([, klass]) => klass.students[student.id])?.[0]
    return { student, klassId }
  })
}

export async function updateStudentClass(studentId: string, classId: string) {
  const db = getDatabase(app)

  // Remove student from all classes
  const classes = await readAllClasses()
  const studentClasses = classes.filter(c => c.students[studentId] === true)
  for (const c of studentClasses) {
    await set(ref(db, `classes/${c.id}/students/${studentId}`), false)
  }

  // Add student to new class (if any)
  if (classId) {
    await set(ref(db, `classes/${classId}/students/${studentId}`), true)
  }
}

export async function createStudent(id: string, name: string) {
  const db = getDatabase(app)
  await set(ref(db, `students/${id}`), { id, name })
}

export function makeStudents(c: FirebaseClass, classStudents: FirebaseClass['students'], context: GlobalContext, activity: FirebaseActivityItem[]): Student[] {
  const active = Object.entries(classStudents)
    .filter(([, active]) => active)
    .map(([id]) => id)
  return active.map(id => makeStudent(id, c, context, activity)).filter(s => s) as Student[]
}

function makeStudent(id: string, klass: FirebaseClass, context: GlobalContext, activity: FirebaseActivityItem[]): Student | null {
  const student = context.students[id] as FirebaseStudent
  if (!student) return null

  // Note: Data is sorted desc by date
  const [mostRecentStudentActivity]: FirebaseActivityItem[] = activity.filter(a => a.student_id === id)

  return {
    key: id,
    id: id,
    name: student.name,
    shortName: shortName(student.name),
    classId: klass.id,
    gender: 'M',
    klass: {
      id: klass.id,
      name: klass.name
    },
    statusKey: mostRecentStudentActivity?.id,
    status: (mostRecentStudentActivity?.action || 'in-class') as StudentStatus,
    statusDate: mostRecentStudentActivity?.when,
    mostRecentIndex: activity.findIndex(activity => mostRecentStudentActivity === activity),
    comment: mostRecentStudentActivity?.comment || '',
    check: false
  }
}
