import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Header } from '../components/Header'
import { HeaderTitle } from '../components/HeaderTitle'
import { FirebaseStudentWithClass, readAllClasses, readAllStudentsWithClass, updateStudentClass } from '../pages/students-grid/firebase.api'
import { FirebaseClass } from '../pages/students-grid/firebase.types'
import { shortName } from '../shortName'

type UpdateStudentCallback = (id: string, classId: string) => any

export function AdminStudentsPage() {
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState<FirebaseStudentWithClass[]>([])
  const [classes, setClasses] = useState<FirebaseClass[]>([])
  useEffect(() => {
    ;(async function () {
      setLoading(true)
      setStudents(await readAllStudentsWithClass())
      setClasses(await readAllClasses())
      setLoading(false)
    })()
  }, [])

  async function handleUpdateStudent(id: string, classId: string) {
    await updateStudentClass(id, classId)
    const students = await readAllStudentsWithClass()
    setStudents(students)
  }

  return (
    <div>
      <Header />
      <HeaderTitle content="Administração / Alunos" loading={loading} />
      <StudentsTable students={students} classes={classes} onUpdateStudent={handleUpdateStudent} />
    </div>
  )
}

function StudentsTable({
  students,
  classes,
  onUpdateStudent
}: {
  students: FirebaseStudentWithClass[]
  classes: FirebaseClass[]
  onUpdateStudent: UpdateStudentCallback
}) {
  return (
    <div>
      <table className={classNames('ui very compact striped unstackable selectable table', {})}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome (curto)</th>
            <th>Turma</th>
          </tr>
        </thead>
        <tbody>
          {students.map(s => (
            <StudentsTableRow key={s.student.id} student={s} classes={classes} onUpdateStudent={onUpdateStudent} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

function StudentsTableRow({
  student,
  classes,
  onUpdateStudent
}: {
  student: FirebaseStudentWithClass
  classes: FirebaseClass[]
  onUpdateStudent: UpdateStudentCallback
}) {
  const [edit, setEdit] = useState(false)
  const [klassId, setKlassId] = useState(student.klassId || '')

  useEffect(() => {
    setKlassId(student.klassId || '')
    setEdit(false)
  }, [student.klassId])

  const classLookup = useMemo(() => {
    const lookup: Record<string, string> = {}
    for (const c of classes) {
      lookup[c.id] = `${c.shortName}`
    }
    return lookup
  }, [classes])

  return (
    <tr>
      <td>{student.student.id}</td>
      <td>
        <div className="flex items-center justify-between">
          {shortName(student.student.name)} <i className="fa fa-info-circle" title={student.student.name} />
        </div>
      </td>
      <td className="w-1/3">
        {!edit && (
          <div className="flex items-center justify-between">
            {student.klassId ? classLookup[student.klassId] : 'sem turma'}{' '}
            <i className="fa fa-pencil text-xs" title={student.klassId} onClick={() => setEdit(true)} />
          </div>
        )}
        {edit && (
          <div className="gap-px-4 flex items-center justify-between">
            <select value={klassId || ''} onChange={e => onUpdateStudent(student.student.id, e.target.value)} className="w-full">
              <option value="">Sem turma</option>
              {classes.map(c => (
                <option key={c.id} value={c.id}>
                  {c.shortName}
                </option>
              ))}
            </select>
            <i
              className="fa fa-times text-xs"
              onClick={() => {
                setEdit(false)
                setKlassId(student.klassId || '')
              }}
            />
          </div>
        )}
      </td>
    </tr>
  )
}
