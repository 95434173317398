import React from 'react'
import { Button, Label, SemanticCOLORS } from 'semantic-ui-react'
import { StudentStatus } from '../../api'

export function StudentLabel({
  status,
  statusDetail = '',
  showDetail = false,
  active = false,
  check = false,
  onClick
}: StudentLabelProps) {
  // prettier-ignore
  switch (status) {
    case 'in-class':
      return <Label size="small" onClick={() => onClick && onClick()} basic={!active} content="Em Sala" detail={showDetail ? statusDetail : ''} color="blue" />
    case 'pickup-request':
      return <Label size="small" onClick={() => onClick && onClick()} basic={!active} content="Pedido de Saída" icon={check ? "check" : undefined} detail={showDetail ? statusDetail : ''} color="red" />
    case 'leaving':
      return <Label size="small" onClick={() => onClick && onClick()} basic={!active} content="Saída de Sala" detail={showDetail ? statusDetail : ''} color="green" />
    case 'out-of-school':
      return <Label size="small" onClick={() => onClick && onClick()} basic={!active} content="Fora" detail={showDetail ? statusDetail : ''} color="grey" />
    default:
      return <Label size="small" onClick={() => onClick && onClick()} basic={!active} content={status} detail={showDetail ? statusDetail : ''} color="grey" />
  }
}

export type StudentLabelProps = {
  status: StudentStatus
  active?: boolean
  check?: boolean
  statusDetail?: string
  showDetail?: boolean
  onClick?: () => any
}

type ActionButtonProps = { color: SemanticCOLORS; text: string; hideIcon?: boolean }
export function ActionButton({ color, text, hideIcon }: ActionButtonProps) {
  if (hideIcon) return <Button content={text} color={color} size="mini" basic />
  return <Button icon="arrow right" labelPosition="right" basic color={color} size="mini" content={text} />
}
