import { StudentStatus } from '../api'
import { PageContext } from './PageContext'

// ################################################################################################################################################
// Sound Notifications
// ################################################################################################################################################
type SoundNotificationsState = {
  active: boolean
  soundName: string
}

const APEE_SOUND_NOTIFICATIONS_KEY = 'apee.1.sound.notifications'

export function getSoundNotificationsState(): SoundNotificationsState {
  const text = window.localStorage.getItem(APEE_SOUND_NOTIFICATIONS_KEY)
  if (!text)
    return {
      active: false,
      soundName: '' // default
    }
  return JSON.parse(text) as SoundNotificationsState
}

export function patchSoundNotificationsState(patch: Partial<SoundNotificationsState>) {
  const state = getSoundNotificationsState()
  const text = JSON.stringify({ ...state, ...patch })
  return window.localStorage.setItem(APEE_SOUND_NOTIFICATIONS_KEY, text)
}

// ################################################################################################################################################
// HIDDEN STUDENT STATUS
// ################################################################################################################################################
const APEE_STUDENT_STATUS_HIDDEN_KEY = (context: PageContext) => `apee.1.student-status.hidden.${context}`

export function getHiddenStatusFromStorage(context: PageContext): StudentStatus[] {
  const hiddenStatusText = window.localStorage.getItem(APEE_STUDENT_STATUS_HIDDEN_KEY(context))
  if (!hiddenStatusText) return defaultFor(context)
  const hiddenStatus = JSON.parse(hiddenStatusText) as StudentStatus[]
  return hiddenStatus
}
export function saveHiddenStatusToStorage(status: StudentStatus[], context: PageContext) {
  window.localStorage.setItem(APEE_STUDENT_STATUS_HIDDEN_KEY(context), JSON.stringify(status))
}

function defaultFor(context: PageContext): StudentStatus[] {
  const defaults: { [key: string]: StudentStatus[] } = {
    [PageContext.Portão]: ['leaving', 'out-of-school'],
    [PageContext.SalaDeAula]: [],
    [PageContext.PedidosDeSaída]: ['in-class', 'out-of-school'],
    [PageContext.ASair]: ['in-class', 'pickup-request']
  }

  return defaults[context] || []
}
